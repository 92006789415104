// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeDash
} from '../../../common/edliy_utils-energy';
const Boxes = {
    box1: function () {
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 10, 7, -6],keepaspectratio: true, axis:true, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});

      brd2.options.layer['line'] =6;
	    brd2.options.layer['point'] =5;
      brd2.options.point.showInfoBox=false;
      //brd2.options.layer['slider'] =17;
      brd2.options.layer['circle'] =5;
      brd2.options.layer['image'] =6;
      brd2.options.layer['arrow'] =6;
      brd2.options.point.highlight=false;
      brd2.options.line.highlight=false;
      brd2.options.text.highlight=false;
      brd2.options.image.highlight=false;
      brd2.options.polygon.highlight=false;
      brd2.options.circle.highlight=false;
      brd2.options.slider.highlight=true;
      brd2.options.curve.highlight=false;
      ////////////////////////////////////////////////////
      makeResponsive(brd2);
      placeTitle(brd2, 'Circular Motion of a Carnival Ride','1. Select initial velocity. 2. Tap on the Go button');
	    //brd2.create('text', [-8.5, 9, '<b>Circular Motion of a Carnival Ride </b>'], {fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd2.create('text', [6, 0.5, '<b>&alpha;(rad)</b>'], {anchorX:'middle',fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd2.create('text', [0.15, 7.5, '<b>T(g units)</b>'], {anchorX:'left',
      fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      var omg0 = placeSlider(brd2, 1, -2, 11, 11, 15, 3, '&nbsp;v_0','&nbsp;m/s');
      //omg0.setAttribute({highlight:true});
      //brd2.create('slider',[[1, -2],[4,-2],[11, 11, 15.]], {baseline:{strokeWidth:7, strokeColor:'grey'}, highline:{strokeWidth:7, strokeColor:'black'}, name:'Velocity (m/s)',size:8,face:'square',
      //snapWidth:1, fillColor:'orange',strokeColor:'black', withTicks:false, label:{fontSize:18, cssStyle:'fontFamily:Oswald;'}});
	    /////////////////////////////////////////////////////////////
      var h =-4; var v=4;
      brd2.create('image', ['/assets/line.svg', [h-4.5, -5],[9,9]],{fixed:true, shadow:false});
      var play = brd2.create('image', ['/assets/go.svg', [2, -5],[1,1]],{fixed:true, shadow:false});
     //var foot = brd2.create('image', ['/assets/ball.svg', [function(){ball.X()-0.25}, function(){return ball.Y();}],[0.5,0.5]],{fixed:true, shadow:false});
     var cen = brd2.create('point', [h, v], {name:'', size:0, fixed:true, strokeColor:'black', fillColor:'grey'});
     //brd2.create('segment', [cen, [h, -9+v]], {visible:true,strokeColor:'grey', strokeWidth:28});
     var x =h; var y=-r+v;
     var ytt=-r; var tt=0; var dt=0.005; var i=0; var j=0; var r=2.75; var theta=0; var tm=0; var tk=0; var thema=0;
     var ball =brd2.create('point', [h, -r+v], {name:'', size:0});
     var temp = brd2.create('point', [h, -r+v], {name:'', size:0});
     var circ =brd2.create('circle', [cen, [h,-r+v]],{strokeWidth:1, strokeColor:'black', fillOpacity:0,fixed:true, dash:1});
     brd2.create('polygon', [[-12, -12+v], [12, -12+v],[12, -9+v],[-12, -9+v]],{vertices:{visible:false, fixed:true},borders:{visible:false}, fillColor:'grey', fillOpacity:1, fixed:true});
     var omg = 0.5;
     var alpha=function(){return -9.8*Math.sin(tt)/r;};
     var run =function(){
     if(theta<2*Math.PI && j>0){
     tt +=dt;
     theta +=omg*dt;
     x = h+r*Math.sin(theta);
     y = -r*Math.cos(theta)+v;
     omg -= 9.8*Math.sin(theta)*dt/r;}
     ball.moveTo([x,y]);
     if(theta<2*Math.PI && j>0 && tt<10){
     setTimeout(run, 0);}
     else{return;}}
     var ang = brd2.create('angle', [temp,cen,ball],{fixed:true, visible:true, radius:1,label:{fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'} });
     var rad = brd2.create('point', [function(){return h+4.*Math.sin(theta)}, function(){return v-4.*Math.cos(theta)}],
     {name:'Centrifugal', size:0, label:{offset:[-15, 0], CssStyle:'fontFamily:Oswald', fontSize:function(){return 22*brd2.canvasHeight/800}}});
     var cent = brd2.create('arrow', [ball, [function(){return h+4.5*Math.sin(theta)}, function(){return v-4.5*Math.cos(theta)}]],
     {visible:true,strokeColor:'red', strokeWidth:function(){return 2*brd2.canvasHeight/800}});
     //cent.setLabel('Centrifugal')
     //cent.label.setAttribute({offset:[0, 0], CssStyle:'fontFamily:Oswald', fontSize:12});
     var ball2 = brd2.create('point', [function(){return ball.X()}, function(){return ball.Y()-2}], {name:'Gravity', size:0, label:{offset:[-15, 0], CssStyle:'fontFamily:Oswald', fontSize:function(){return 22*brd2.canvasHeight/800}}});
     var grav = brd2.create('arrow', [ball, ball2],
     {name:'a',visible:true,strokeColor:'blue', strokeWidth:function(){return 2*brd2.canvasHeight/800}});
     //grav.setLabel('Gravity')
    // grav.label.setAttribute({offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 22*brd2.canvasHeight/800}});
     var ship =brd2.create('image', ['/assets/ufo.svg', [function(){return ball.X()-0.675}, function(){return ball.Y()-1}],[1.35,1]]);
     brd2.create('image', ['/assets/rocks.svg', [-0.55+h,-9.5+v],[1.1,1]],{fixed:true});
     play.on('down', function(){omg=omg0.Value()/r; x=h; y=-r+v; tt=0; i=0; j=1; tm=0; theta=0; ball.moveTo([h,-r+v]),run();});
     omg0.on('down', function(){theta=0; j=0; x=h; y=-r+v; tm=0; ball.moveTo([h,-r+v])});
     //omg0.coords.on('update', function(){alert('fdaf')});
     brd2.create('segment', [cen, [function(){return ball.X()}, function(){return ball.Y()}]], {visible:true,strokeColor:'black', strokeWidth:function(){return 3*brd2.canvasHeight/800}});
     brd2.create('image', ['/assets/circle.svg', [h-0.35,v-0.35],[0.7,0.7]],{fixed:true, shadow:false});
     brd2.create('functiongraph',
     [function(x){return omg0.Value()*omg0.Value()/r/9.8-(r-r*Math.cos(x))*2/r+Math.cos(x)}, 0, function(){return theta;}],{strokeWidth:4, strokeColor:'grey'});

    },
}
export default Boxes;
