<template>
  <div>
    <h3 ref="what-is-circular-motion">
      What is Circular Motion?
    </h3>
    <p>
      Circular motion is a two-dimensional motion in which an object moves along the circumference of a circular path.
      In circular motion, the velocity of the object keeps changing even if it is moving with a constant speed since the
      direction of motion changes at every point. Therefore, circular motion is always an accelerated motion.
    </p>
    <h3 ref="kinematics-of-circular-motion">
      Kinematics of Circular Motion
    </h3>
    <p>
      In circular motion, kinematics is studied with the help of two kinds of variables &mdash; linear variables and angular
      variables. Let's have a look at each one of them one by one.
    </p>
    <h5>Angular Velocity</h5>
    <p>
      Angular velocity is the angle in radians that a body moving in circular motion rotates per unit time. In other
      words, it is the rate of change of the angle \(\theta\) moved by the body. It is denoted by \(\omega\) and is measured in rad/s.
      If the body rotates by an \(\Delta \theta\) in time \(\Delta t\), angular velocity is given as:
      $$\text{Angular velocity}=\omega=\lim_{\Delta t \to \theta} \frac{\Delta \theta}{\Delta t}=\frac{\Delta \theta}{dt}$$
    </p>
    <h5>Linear Velocity</h5>
    <p>
      Linear velocity of a body moving in circular motion is given by:
      $$\text{Linear velocity}=\mathbf{v}=\frac{d\mathbf{s}}{dt}$$
      where \(\mathbf{s}\) is the displacement of the body.
      <br><br>
      The magnitude of linear velocity is called <b>linear speed</b> and is given as $$v=\left|\frac{d\mathbf{s}}{dt}\right|$$
      Linear speed and angular speed are related as follows: $$v=r\omega$$
    </p>
    <h5>Angular Acceleration</h5>
    <p>
      It is the rate of change of angular velocity of the body. It is denoted by α.
      $$\text{Angular Acceleration}=\alpha=\frac{d\omega}{dt}=\frac{d^2\theta}{dt^2}$$
    </p>
    <h5>Linear Acceleration</h5>
    <p>In circular motion, linear acceleration is divided into two components:</p>
    <ol>
      <li>
        <h5>Tangential acceleration (a<sub>t</sub>) </h5>
        <br>It is the component of linear acceleration along the direction of motion of the body i.e, in tangential
        direction. It is responsible for the change in speed of the object in circular motion.
        $$a_t=\frac{dv}{dt}=\frac{d|\textbf{v}|}{dt}$$
        <br> Tangential acceleration and angular acceleration are related as follows:
        $$a_t=r\alpha$$
      </li>

      <li>
        <h5>Centripetal or radial acceleration (a<sub>r</sub>)</h5>
        <br>It is the component of linear acceleration perpendicular to the direction of motion of the body. It is
        responsible for the change in the direction of the body.

        $$a_r=\frac{v^2}{r}$$

        where \(v\) is the linear speed of the body and \(r\) is the radius of the
        circular path.
      </li>
    </ol>
    <br>
    <p>
      Net acceleration of the body is given as the vector sum of the two components of acceleration given
      above.
      $$\text{Net acceleration}=a=\sqrt{a_t^2+a_r^2}$$
    </p>

    <br><b>Uniform Circular Motion: </b>
    <p>
      If the object moving in circular motion moves with a constant speed, its motion is called uniform circular motion.
      Its tangential acceleration is zero but centripetal acceleration is not zero.
    </p>
    <h3 ref="dynamics-of-circular-motion">
      Dynamics of Circular Motion
    </h3>
    <h5>Centripetal force</h5>
    <p>
      In circular motion, the direction of the particle changes at every moment. At each moment instead of travelling
      straight, it turns towards the center, therefore there must be a force responsible for changing the direction of
      the particle. This force responsible for turning the direction of motion of the body towards the center of the
      circle is called the centripetal force. It is always directed towards the center of the circle.
    </p>
    <p>
      For example, when moon moves around the earth in a circular path, the gravitational force of attraction on the
      moon by the earth provides the required centripetal force.
      Now let us have a look at the magnitude of centripetal force.
      $$
      \begin{aligned}
      \text{Centripetal Force} &= m \times a_r \\
      &= m \times \frac{v^2}{r} \\
      &= \frac{mv^2}{r} \\
      &=  m \omega^2 r \quad (\text{Since } v=r\omega)
      \end{aligned}
      $$
      where \(v\) is the linear speed, \(\omega\) is the angular speed and \(r\) is the radius of the circular path.
    </p>
    <h5>Centrifugal Force</h5>
    <p>
      Centrifugal force is not an actual force, it is the pseudo force that appears to act on the object moving in a
      circular motion when viewed in a rotating frame of reference. It is directed away from the center of the circular
      path the object is moving in. For a body of mass m rotating in circular path of radius \(r\) with an angular velocity
      \(\omega\), magnitude of centrifugal force is equal to
      \(m \omega^2 r\) or \(m v^2 /r\).
      $$F = \frac{mv^2}{r} = m \omega^2 r$$

      <br>Let’s take an example. Suppose a stone is tied to a string and it is whirled around the string. When it is viewed
      from a non-inertial frame of reference (rotating frame of reference) it appears to be stationary however, we know that
      the force applied by the string is still acting on the stone towards the center therefore a pseudo force is applied
      in a direction away from the center which is the centrifugal force in this case.
    </p>
    <h3 ref="magicgraph">
      MagicGraph | Circular Motion
    </h3>
    <p>
      Shown is a carnival ride that travels along a circular path with a constant linear speed \(v_0\).
      As the ride travels along the circular path, the tension in the bar connecting the center to the ride changes.
      Through this MagicGraph, students will learn:
    </p>
    <ul style="list-style-type: square;">
      <li>At what point on the circular path, the tension in the connecting bar is maximum? </li>
      <li> At what point on the circular path, the tension in the connection bar is minimum? </li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
// NOTE: We need to investigate EW-5
// (https://sandeep183.atlassian.net/browse/EW-5) for these magicgraphs -- they
// do not work well on touchscreens right now.
import Boxes from './Boxes.js'
export default {
  name: 'CircularMotion',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Circular Motion';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is Circular Motion?', img:'/assets/number-1.svg', action: () => this.goto('what-is-circular-motion')},
      {title: 'Kinematics of Circular Motion', img:'/assets/number-2.svg', action: () => this.goto('kinematics-of-circular-motion')},
      {title: 'Dynamics of Circular Motion', img:'/assets/number-3.svg', action: () => this.goto('dynamics-of-circular-motion')},
      {title: 'Magicgraph | Circular Motion',img:'/assets/touch.svg', action: () => this.goto('magicgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Circular Motion',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Projectile Motion'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
